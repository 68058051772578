import React from 'react'

import Notes from '../components/views/notes/notes'
import { LayoutLoggedIn } from '../components/views/layout'

export default function NotesPage() {
   return (
      <LayoutLoggedIn>
         <Notes />
      </LayoutLoggedIn>
   )
}
