import React, { useState, useEffect, useMemo, useRef } from 'react'
import * as yup from 'yup'
import { Col, Row } from 'react-grid-system'
import { useLocation } from '@reach/router'
import { AddEditNoteModal } from './addEditNoteModal'
import { TableWrapper } from '../../common/table/TableWrapper'
import {
   CsvDownloadForm,
   PageHeader,
   Search,
   Modal,
   TrueOrFalse,
   Button,
   CrudActionButtons,
   LoadingOverlay,
   SnackBar,
   SlidePanel,
   Filters,
   Svg,
} from '../../common'

import FilterButton from '../../common/filters/filterButton/filterButton'
import FilterTags from '../../common/filters/filterTags/filterTags'
import { Actions, PAGINATION } from '../../../constants/tables'
import { Severity } from '../../../types'
import { CRUD_TABLE } from './constants'
import {
   DateRangeFilter,
   DropdownFilter,
   TextFilter,
} from '../../common/filters/filterTypes'
import {
   isEmptyObject,
   renderDateOrNothing,
} from '../../../common/utils/functions'
import { convertNoteTypesToOptions } from '../../common/filters/helpers'
import { PATH } from '../../../constants/global'
import { Item } from '../../../hooks/useUserItems/useUserItems.type'
import {
   useCrudItems,
   useFilters,
   useUserItems,
   useNoteTypes,
   useNoteDetails,
} from '../../../hooks'

import * as styles from '../../views/history/itemHistory/itemHistory.module.scss'
import * as noteStyles from './notes.module.scss'
import * as tableStyles from '../../common/table/table.module.scss'

import {
   Filters as FiltersObject,
   FiltersForAPI,
   SortDirection,
   SortObject,
} from '../../../hooks/useFilters/useFilters.type'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { format } from 'date-fns'
import { FORMATS } from '../../../constants/datetime'
import { flattenFilterObj } from '../../../hooks/useFilters/useFilters.helpers'
import SortButton from '../../common/filters/sortButton/sortButton'
import { ColumnSortPanel } from '../items/columnSortPanel/columnSortPanel'
import { SnackBarState } from '../../common/snackBar/types'
import { TableSortHeader } from '../../common/table/tableSortHeader'

const {
   extraClassName,
   modalTitle,
   placeholder,
   addButtonText,
   key,
   crudPaths,
   tableTitle,
   downloadRequest,
} = CRUD_TABLE

const filterSchema = yup.object().shape({})

function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.startDate?.value instanceof Date && {
         startDate: formatDate(filters?.startDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.endDate?.value instanceof Date && {
         endDate: formatDate(filters?.endDate?.value),
      }),
   }

   const status = Array.isArray(filters?.status?.value)
      ? filters?.status.value[0].value
      : undefined

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
      status,
   }
}

const defaultItem = {
   ID: '',
   Title: '',
   Barcode: '',
   SerialNumber: '',
   CustomText1Value: '',
   ToolLoanId: '',
}

const closedSnackbar = {
   disableAutoClose: false,
   message: '',
   severity: Severity.INFO,
}

const Notes = () => {
   const { query, skip, take } = PAGINATION
   const {
      data: crudData,
      get,
      remove,
      totalItems,
      setPath,
      isLoading,
      refresh,
      isRefetching,
      isCreating,
      isSuccessCreate,
      isErrorCreate,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
   } = useCrudItems({ query, skip, take, apiMethod: 'post' })
   const { search: queryString } = useLocation()
   const {
      errors,
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(filterSchema, {})
   const { list: noteTypes } = useNoteTypes()
   const { list: items, isLoading: isLoadingUserItems } = useUserItems()
   const { getNote } = useNoteDetails()

   const [selectedNote, setSelectedNote] = useState<any>()
   const [selectedItem, setSelectedItem] = useState<Item>(defaultItem)
   const [selectedNoteType, setSelectedNoteType] = useState<
      Option | undefined
   >()
   const [searchMode, setSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset] = useState<boolean>(false)
   const [isAddEditModalVisible, setIsAddEditModalVisible] =
      useState<boolean>(false)
   const [isDeleteModalVisible, setIsDeleteModalVisible] =
      useState<boolean>(false)
   const [isExportModalVisible, setIsExportModalVisible] =
      useState<boolean>(false)
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const [snackbar, setSnackbar] = useState<SnackBarState>(closedSnackbar)
   const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
   const [isDownloading, setIsDownloading] = useState<boolean>(false)
   const [filterValues, setFilterValues] = useState({})

   const [isSortPanelOpen, setIsSortPanelOpen] = useState<boolean>(false)
   const [defaultHeaderSortBy, _setDefaultHeaderSortBy] =
      useState<string>('Added On')
   const [activeSortHeader, setActiveSortHeader] = useState<SortObject>({
      sortBy: 'createdon',
      sortDirection: SortDirection.ASCENDING,
   })

   const formRef = useRef<HTMLFormElement>()
   const sortRef = useRef<HTMLFormElement>()
   const buttonRef = useRef<HTMLInputElement>(null)

   useEffect(() => {
      setPath(crudPaths, key)
   }, [])

   useEffect(() => {
      if (queryString) {
         const params = new URLSearchParams(queryString)
         getNote(parseInt(params.get('noteId')))
      }
   }, [])

   const resetAndCloseModal = () => {
      setSelectedNote(undefined)
      setSelectedItem(defaultItem)
      setSelectedNoteType(undefined)
      setIsAddEditModalVisible(false)
      setIsDeleteModalVisible(false)
      setIsExportModalVisible(false)
   }

   const refetchTableData = () => {
      refresh()
   }

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      getItems(searchCriteria, 0, pageSized, filters, activeSortHeader)
      setFilterValues(formatFiltersForAPI(filters))
   }

   const handleSortReset = () => {
      sortRef.current.reset()
      handleHeaderSortClick(defaultHeaderSortBy, SortDirection.ASCENDING)
      setIsSortPanelOpen(false)
   }

   const handleHeaderSortClick = (
      sortBy: string,
      sortDirection: SortDirection
   ) => {
      getItems(query, skip, take, savedFilters, {
         sortBy: sortBy.toLocaleLowerCase(),
         sortDirection,
      })
      if (isSortPanelOpen) {
         setIsSortPanelOpen(false)
      }
      setActiveSortHeader({
         sortBy,
         sortDirection,
      })
   }

   const getItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: FiltersObject,
      sortObject?: SortObject // will only have sort object if being called from handleHeader sort
   ) => {
      const formattedFilters = formatFiltersForAPI({
         ...(filters || savedFilters),
      })
      setFilterValues(formattedFilters)

      get(
         query,
         skip,
         take,
         formattedFilters,
         sortObject?.sortBy ?? activeSortHeader.sortBy,
         sortObject?.sortDirection ?? activeSortHeader.sortDirection
      )
   }

   const handleSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues({ ...formattedFilters, query })

      get(query, skip, take, formattedFilters)
   }

   const handleClose = () => {
      // onReset()
      resetAndCloseModal()
   }

   const showActionForm = (action, id?: string) => {
      if (action === Actions.Edit) {
         //@ts-ignore
         const noteToEdit = crudData.find((note) => note?.Id === id)

         prePopulateEditNote(noteToEdit, items)
      } else if (action === Actions.Delete) {
         //@ts-ignore
         const noteToEdit = crudData.find((note) => note?.Id === id)

         setSelectedNote(noteToEdit)
         setIsDeleteModalVisible(true)
      } else {
         setSelectedNote(undefined)
         setSelectedItem(defaultItem)
         setSelectedNoteType(undefined)
         setIsAddEditModalVisible(true)
      }
   }

   const prePopulateEditNote = (note: any, items: Item[]) => {
      const noteType = noteTypes.find(
         (noteType) => noteType.Id === note?.NoteTypeId
      )
      const item = items.find(
         (item) => item?.ID.toString() === note?.ToolId.toString()
      )
      setSelectedNote(note)
      setSelectedNoteType(
         !!note?.NoteTypeId && !!noteType
            ? {
                 value: noteType?.Id.toString(),
                 label: noteType?.Title,
              }
            : undefined
      )
      setSelectedItem(!!note?.ToolId && !!item ? item : defaultItem)
      setIsAddEditModalVisible(true)
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[] | Option
   ) => {
      const filterOtions = Array.isArray(options)
         ? options?.map((option) => ({
              label: option.label,
              value: option.value,
           }))
         : [options]

      modify(name, label, filterOtions)
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
   }

   const handleDeleteNote = () => {
      remove({ id: selectedNote ? selectedNote?.Id : '' })
      resetAndCloseModal()
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'Id',
            minWidth: 80,
            align: 'center',
            Cell: (cell) => (
               <CrudActionButtons
                  cell={cell}
                  action={showActionForm}
                  editAction
                  deleteAction
               />
            ),
         },
         {
            headerTitle: 'Item Name',
            Header: () => (
               <TableSortHeader
                  header="Item Name"
                  id="toolname"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'ToolName',
            Cell: (cell: { row: { original: { ToolName: string } } }) => {
               return (
                  <div className={tableStyles.tableColumnCellWrapper}>
                     {cell.row.original.ToolName}
                  </div>
               )
            },
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Serial No.',
            Header: () => (
               <TableSortHeader
                  header="Serial No."
                  id="ToolSerialNumber"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'ToolSerialNumber',
            Cell: (cell: {
               row: { original: { ToolSerialNumber: string } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.ToolSerialNumber}
               </div>
            ),
            align: 'center',
            width: 130,
            minWidth: 20,
         },
         {
            headerTitle: 'Barcode',
            Header: () => (
               <TableSortHeader
                  header="Barcode"
                  id="ToolBarcode"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'ToolBarcode',
            Cell: (cell: { row: { original: { ToolBarcode: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.ToolBarcode}
               </div>
            ),
            align: 'center',
            width: 130,
            minWidth: 20,
         },
         {
            headerTitle: 'Added By',
            Header: () => (
               <TableSortHeader
                  header="Added By"
                  id="CreatedByName"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'CreatedByName',
            width: 130,
            minWidth: 20,
         },
         {
            headerTitle: 'Added On',
            Header: () => (
               <TableSortHeader
                  header="Added On"
                  id="CreatedOn"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'CreatedOn',
            align: 'center',
            width: 110,
            minWidth: 20,
            Cell: renderDateOrNothing,
         },
         {
            headerTitle: 'Note Type',
            Header: () => (
               <TableSortHeader
                  header="Note Type"
                  id="NoteTypeTitle"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'NoteTypeTitle',
            Cell: (cell: { row: { original: { NoteTypeTitle: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.NoteTypeTitle}
               </div>
            ),
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Note',
            Header: () => (
               <TableSortHeader
                  header="Note"
                  id="NoteText"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'NoteText',
            Cell: (cell: { row: { original: { NoteText: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.NoteText}
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Due Date',
            Header: () => (
               <TableSortHeader
                  header="Due Date"
                  id="DueDate"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'DueDate',
            align: 'center',
            width: 110,
            minWidth: 20,
            Cell: renderDateOrNothing,
         },
         {
            headerTitle: 'Completed',
            Header: () => (
               <TableSortHeader
                  header="Completed"
                  id="Completed"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'Completed',
            width: 100,
            minWidth: 20,
            align: 'center',
            Cell: ({ row }) => <TrueOrFalse status={row.original.Completed} />,
         },
         {
            headerTitle: 'Completed Date',
            Header: () => (
               <TableSortHeader
                  header="Completed Date"
                  id="CompletedDate"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'CompletedDate',
            width: 110,
            minWidth: 20,
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            headerTitle: 'Completed By',
            Header: () => (
               <TableSortHeader
                  header="Completed By"
                  id="CompletedBy"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'CompletedBy',
            width: 130,
            minWidth: 20,
         },
         {
            headerTitle: 'Completed Cost',
            Header: () => (
               <TableSortHeader
                  header="Completed Cost"
                  id="CompletedCost"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'CompletedCost',
            width: 120,
            minWidth: 20,
            Cell: (cell: { row: { original: { CompletedCost: number } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.CompletedCost
                     ? `$${cell.row.original.CompletedCost}`
                     : '-'}
               </div>
            ),
         },
         {
            headerTitle: 'Odometer',
            Header: () => (
               <TableSortHeader
                  header="Odometer"
                  id="Odometer"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'Odometer',
            Cell: (cell: {
               row: { original: { Odometer: string | number } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Odometer.toLocaleString('en-US') ?? '-'}
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Hours',
            Header: () => (
               <TableSortHeader
                  header="Hours"
                  id="Hours"
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'Hours',
            Cell: (cell: { row: { original: { Hours: string | number } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Hours.toLocaleString('en-US') ?? '-'}
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
      ],
      [crudData, items]
   )

   const tableSortColumnOptions = useMemo(
      () =>
         columns
            .filter((col) => col.accessor !== 'Id')
            .map((col) => {
               return {
                  value: col.accessor.toLocaleLowerCase(),
                  label: col.headerTitle,
               }
            }),
      [columns]
   )

   const handleSnackbarMessage = (message: string, severity: Severity) => {
      setSnackbar({ ...snackbar, message, severity })
   }

   // handle actions
   useEffect(() => {
      handleSnackbarMessage('Note has been updated.', Severity.SUCCESS)
   }, [isSuccessUpdate])

   useEffect(() => {
      handleSnackbarMessage('Note has been created.', Severity.SUCCESS)
   }, [isSuccessCreate])

   useEffect(() => {
      handleSnackbarMessage('Note has been removed.', Severity.SUCCESS)
   }, [isSuccessRemove])

   // handle errors
   useEffect(() => {
      handleSnackbarMessage(
         'Creating a note has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorCreate])

   useEffect(() => {
      handleSnackbarMessage(
         'Updating a note has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorUpdate])

   useEffect(() => {
      handleSnackbarMessage(
         'Deleting a note has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorRemove])

   const showSnackbar =
      isSuccessUpdate ||
      isSuccessCreate ||
      isSuccessRemove ||
      isErrorCreate ||
      isErrorUpdate ||
      isErrorRemove
   const showLoadingSpinner =
      isLoading ||
      isUpdating ||
      isCreating ||
      isRemoving ||
      isRefetching ||
      isLoadingUserItems

   const noteTypeOptions = convertNoteTypesToOptions(noteTypes)

   return (
      <div>
         {showLoadingSpinner && <LoadingOverlay />}
         <SnackBar
            message={snackbar.message}
            open={showSnackbar}
            severity={snackbar.severity}
            disableAutoClose={snackbar.disableAutoClose}
         />

         <PageHeader title={tableTitle}>
            <Button
               onClick={() => showActionForm(Actions.Add, '')}
               preserveText
               variant="plain"
               minWidth="0"
            >
               <Svg id="plus" /> {addButtonText}
            </Button>
            <Button
               onClick={() => setIsExportModalVisible(true)}
               preserveText
               variant="plain"
               disabled={!Boolean(crudData.length)}
               minWidth="0"
            >
               <Svg id="download" width={8} height={8} /> Export to Excel
            </Button>
         </PageHeader>

         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
            </div>
            <div className={styles.searchWrapperFilter}>
               <SortButton
                  isActive={true}
                  onClick={() => setIsSortPanelOpen(!isSortPanelOpen)}
               />
            </div>
            <div className={styles.searchWrapperFilter}>
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />

         <TableWrapper
            isLoading={isLoading}
            data={crudData}
            columns={columns}
            totalCount={totalItems}
            getItems={getItems}
            takeItems={PAGINATION.take}
            skipItems={PAGINATION.skip}
            setSearchMode={setSearchMode}
            searchMode={searchMode}
            isPageReset={isPageReset}
            setPageSized={setPageSized}
            extraClassName={extraClassName}
            searchCriteria={searchCriteria}
         />

         <ColumnSortPanel
            isSortPanelOpen={isSortPanelOpen}
            onClose={() => setIsSortPanelOpen(false)}
            handleSortReset={handleSortReset}
            handleApplySortOptions={handleHeaderSortClick}
            sortRef={sortRef}
            activeSortHeader={activeSortHeader}
            columnOptions={tableSortColumnOptions}
         />

         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
               <form ref={formRef}>
                  <DropdownFilter
                     defaultValue={tmpFilters?.noteTypes?.value as Option[]}
                     isMulti
                     id="noteTypes"
                     onChange={handleDropdownChange}
                     options={noteTypeOptions}
                     title="Note Type"
                  />

                  <DropdownFilter
                     defaultValue={tmpFilters?.status?.value as Option[]}
                     id="status"
                     onChange={handleDropdownChange}
                     options={[
                        { value: '0', label: 'All' },
                        { value: '1', label: 'Completed' },
                        { value: '2', label: 'Not Completed' },
                     ]}
                     title="Status"
                  />

                  <TextFilter
                     defaultValue={tmpFilters?.completedBy?.value as string}
                     error={errors?.barcode}
                     id="completedBy"
                     onChange={modify}
                     title="Completed By"
                  />

                  <DateRangeFilter
                     dateProps={{
                        from: {
                           defaultValue: tmpFilters?.dueStartDate
                              ?.value as Date,
                           error: errors?.dueStartDate,
                           id: 'dueStartDate',
                           placeholder: 'Start Date',
                        },
                        to: {
                           defaultValue: tmpFilters?.dueEndDate?.value as Date,
                           error: errors?.dueEndDate,
                           id: 'dueEndDate',
                           placeholder: 'End Date',
                           maxEndDate: new Date(2099, 1, 1),
                        },
                     }}
                     id="dueDate"
                     onChange={modify}
                     title="Due Date"
                  />

                  <DateRangeFilter
                     dateProps={{
                        from: {
                           defaultValue: tmpFilters?.completedStartDate
                              ?.value as Date,
                           error: errors?.completedStartDate,
                           id: 'completedStartDate',
                           placeholder: 'Start Date',
                        },
                        to: {
                           defaultValue: tmpFilters?.completedEndDate
                              ?.value as Date,
                           error: errors?.completedEndDate,
                           id: 'completedEndDate',
                           placeholder: 'End Date',
                        },
                     }}
                     id="completedDate"
                     onChange={modify}
                     title="Completed Date"
                  />
               </form>
            </Filters>
         </SlidePanel>

         <Modal
            isModalVisible={isExportModalVisible}
            closeModal={() => setIsExportModalVisible(false)}
            title={`Export notes`}
         >
            <CsvDownloadForm
               primaryActionText="Export"
               secondaryActionText="Cancel"
               criteria={''}
               data={crudData}
               fileName="Notes.xlsx"
               closeModal={handleClose}
               path={PATH.NOTES.DOWNLOAD}
               query={searchCriteria}
               fullDownload={downloadRequest}
               filters={filterValues}
               setIsDownloading={setIsDownloading}
               isDownloading={isDownloading}
               defaultParams={{}}
               buttonRef={buttonRef}
            />
         </Modal>

         <Modal
            isModalVisible={isDeleteModalVisible}
            closeModal={handleClose}
            title={`Delete${modalTitle}`}
         >
            <div className={noteStyles.deleteForm}>
               <p>Are you sure you wish to delete this note?</p>

               <Row gutterWidth={20}>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="danger"
                        onClick={handleDeleteNote}
                     >
                        Delete
                     </Button>
                  </Col>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="tertiary"
                        onClick={handleClose}
                        isReset
                     >
                        Cancel
                     </Button>
                  </Col>
               </Row>
            </div>
         </Modal>
         {isAddEditModalVisible && (
            <AddEditNoteModal
               selectedNote={selectedNote}
               setSelectedNote={setSelectedNote}
               isEditModalOpen={isAddEditModalVisible}
               setIsEditModalOpen={setIsAddEditModalVisible}
               selectedItem={selectedItem}
               setSelectedItem={setSelectedItem}
               selectedNoteType={selectedNoteType}
               setSelectedNoteType={setSelectedNoteType}
               refetchNotes={refetchTableData}
               noteTypes={noteTypes}
               items={items}
            />
         )}
      </div>
   )
}

export default Notes
